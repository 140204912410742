import React, { useEffect, useRef, useState } from 'react'
import '../../styles/Header.css'
import { Container } from 'reactstrap'
import { NavLink, Link } from 'react-router-dom'
import { BsCart2 } from 'react-icons/bs';
import { BiUser, BiMenu } from 'react-icons/bi';
import logo from '../../assets/logo.png'
import { useSelector } from 'react-redux';
// import { cartUiActions } from '../../store/shoppingCart/cartUiSlice';

const nav__links = [
  {
    display: 'Home',
    path: '/home'
  },
  {
    display: 'Products',
    path: '/products'
  },
  // {
  //   display: 'Cart',
  //   path: '/cart'
  // },
  {
    display: 'Contact',
    path: '/contact'
  },
  {
    display: 'Account',
    path: '/account'
  }
]

export default function Header({ setShowLogin }) {

  const menuRef = useRef(null)
  const totalQuantity = useSelector(state => state.cart.totalQuantity)

  const toggleMenu = () => menuRef.current.classList.toggle('show__menu')
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);

  // const toggleCart = () => {
  //   dispatch(cartUiActions.toggle())
  // }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        setIsHeaderSticky(true)
      } else {
        setIsHeaderSticky(false)
      }
    });

    return () => window.removeEventListener("scroll", ()=>{});
  }, []);


  return (
    <div className={isHeaderSticky ? 'sticky__header' : 'header'}>
      <Container>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='header__logo d-flex flex-column text-align-center align-items-center justify-content-center'>
            <img src={logo} alt="logo" />
            <h5>ZoodBia</h5>
          </div>

          <div className='navigation' ref={menuRef} onClick={toggleMenu}>
            <div className='header__menu d-flex align-items-center gap-5'>
              {
                nav__links.map((item, index) => (
                  <NavLink to={item.path} key={index} className={navClass => navClass.isActive ? 'active__header__menu' : ''}>
                    {item.display}
                  </NavLink>
                ))
              }
            </div>
          </div>

          <div className='d-flex align-items-center gap-4'>
            <span className='cart'>
              <Link to='/cart'>
                <BsCart2 className='cart__icon' />
                <span className='cart__badge'>{totalQuantity}</span>
              </Link>

            </span>

            <span className='user' onClick={() => setShowLogin(true)}>
              <BiUser className='user__icon' />
            </span>

            <span className='mobile__menu' onClick={toggleMenu}>
              <BiMenu className='menu__icon' />
            </span>
          </div>
        </div>
      </Container>
    </div>
  )
}
