import React from 'react'
import '../../styles/Category.css'
import { Container, Row, Col } from 'reactstrap'
import categoryImg01 from '../../assets/category-01.png'
import categoryImg02 from '../../assets/category-02.png'
import categoryImg03 from '../../assets/category-03.png'
import categoryImg04 from '../../assets/category-04.png'
import categoryImg05 from '../../assets/category-05.png'
import categoryImg06 from '../../assets/category-06.png'

const categoryData = [
    {
        categoryName: 'Fruits & Veges',
        imgUrl: categoryImg01,
        backgroundColor: "#53B1751A"
    },
    {
        categoryName: 'Cooking Oil & Ghee',
        imgUrl: categoryImg02,
        backgroundColor: '#F8A44C1A'
    },
    {
        categoryName: 'Meat & Fish',
        imgUrl: categoryImg03,
        backgroundColor: '#F7A59340'
    },
    {
        categoryName: 'Bakery & Snacks',
        imgUrl: categoryImg04,
        backgroundColor: '#D3B0E040'
    },
    {
        categoryName: 'Dairy & Eggs',
        imgUrl: categoryImg05,
        backgroundColor: '#FDE59840'
    },
    {
        categoryName: 'Beverages',
        imgUrl: categoryImg06,
        backgroundColor: '#B7DFF540'
    }
]

export default function Category() {
    return (
        <div className='pt-0 mt-5'>
            <Container>
                <Row className='g-4 row-cols-lg-5 row-cols-xs-2 row-cols-md-3'>
                    {
                        categoryData.map((item, index) => (
                            <Col className='mb-4'>
                                <div className='category__item' key={index} style={{ backgroundColor: item.backgroundColor }}>
                                    <div className='category__img'>
                                        <img src={item.imgUrl} alt='category__item' />
                                    </div>
                                    <h6>{item.categoryName}</h6>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </div>

    )
}
