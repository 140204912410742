import React from 'react'
import '../../styles/AppDownload.css'
import phone from '../../assets/phone.png'
import play_store from '../../assets/google-play.jpg'
import app_store from '../../assets/app-store.jpg'

import { Container, Row, Col } from 'reactstrap'

export default function AppDownload() {
    return (
        <section className='app__download'>
            <div>
                <Container className='p-5' style={{backgroundColor:"#53b17526"}}>
                    <Row>
                        <Col md='4'>
                            <img className='app__download__preview' src={phone} alt='' />
                        </Col>

                        <Col md='8' className='app__download__right'>
                            <h5>Shop Faster with ZoodBia App</h5>
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis sed ptibus liberolectus nonet psryroin. Amet sed lorem posuere sit iaculis amet, ac urna. Adipiscing fames semper erat ac in suspendisse iaculis. Amet blandit tortor praesent ante vitae. A, enim pretiummi senectus magna. Sagittis sed ptibus liberolectus non et psryroin.</span>
                            <div className='app__download__platform'>
                                <img src={app_store} alt='' />
                                <img src={play_store} alt='' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}
