import React, { useState } from 'react'
import '../styles/DeliveryAddresses.css'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import AccountMenu from '../components/account/AccountMenu'
import AddressCard from '../components/account/AddressCard'

const addresses = [
    {
        name: "Name 1",
        address: "Unit 123, Toronto, ON",
        isDefault: true
    },
    {
        name: "Name 2",
        address: "Unit 456, Toronto, ON",
        isDefault: false
    },
]

export default function DeliveryAddresses() {

    const [isAddAddress, setIsAddAddress] = useState(false)

    return (
        <section>
            <Container>
                <Link to='/account' className='main__nav'>My account / </Link><Link to='/account/addresses' className='sub__nav'>Addresses</Link>
            </Container>

            <Container className='pt-5'>
                <Row>
                    <Col lg='3' md='4'>
                        <AccountMenu currNav={'Addresses'} />
                    </Col>

                    <Col lg='9' md='8' sm='12' xs='12'>
                        <div className='delivery__addresses'>
                            <h4>Addresses</h4>

                            {isAddAddress
                                ? <div className='add__addresses__forms'>
                                    <span>Add address</span>
                                    <span className='required__field__note'>* required field</span>

                                    <div className='input__field'>
                                        <span>First name*</span>
                                        <input type='text' required/>
                                    </div>
                                    <div className='input__field'>
                                        <span>Last name*</span>
                                        <input type='text' required/>
                                    </div>
                                    <div className='input__field'>
                                        <span>Street address*</span>
                                        <input type='text' required/>
                                    </div>
                                    <div className='input__field'>
                                        <span>Apt, suite, etc.</span>
                                        <input type='text' />
                                    </div>
                                    <div className='input__field'>
                                        <span>City*</span>
                                        <input type='text' required/>
                                    </div>
                                    <div className='input__field'>
                                        <span>Province*</span>
                                        <input type='text' required/>
                                    </div>
                                    <div className='input__field'>
                                        <span>Postal code*</span>
                                        <input type='text' required/>
                                        <span className='contact__note'>We'll contact you in case anything comes up with your order</span>
                                    </div>
                                    <div className='input__field'>
                                        <span>Delivery instructions</span>
                                        <input type='text' />
                                    </div>
                                    <div className='d-flex gap-2 preferred__box'>
                                        <input type='checkbox' />
                                        <span>Set as my preferred delivery address</span>
                                    </div>
                                    <div className='cancel__and__save__btns'>
                                        <button className='cancel__btn' onClick={() => setIsAddAddress(false)}>Cancel</button>
                                        <button className='save__btn' onClick={() => setIsAddAddress(false)}>Save</button>
                                    </div>
                                </div>
                                : <div className='delivery__addresses__list'>
                                    <div className='align-items-start'>
                                        <button onClick={() => setIsAddAddress(true)}>+ Add address</button>
                                    </div>

                                    {
                                        addresses.map((item, index) => (
                                            <AddressCard item={item} />
                                        ))
                                    }
                                </div>
                            }

                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}
