import React from 'react'
import '../styles/Wallet.css'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import AccountMenu from '../components/account/AccountMenu'
import visa__icon from '../assets/visa.png'

export default function Wallet() {
    return (
        <section className='wallet'>
            <Container>
                <Link to='/account' className='main__nav'>My account / </Link><Link to='/wallet' className='sub__nav'>Wallet</Link>
            </Container>

            <Container className='pt-5'>
                <Row>
                    <Col lg='3' md='4'>
                        <AccountMenu currNav={'Wallet'} />
                    </Col>

                    <Col lg='9' md='8' sm='12' xs='12'>
                        <div className='d-flex flex-column gap-5'>
                            <h4>Wallet</h4>

                            <div className='payment__methods'>
                                <h6>Payment methods (1)</h6>
                                <button>Add new payment method</button>
                            </div>

                            <div className='card__details'>
                                <h6>Credit or debit card (1)</h6>

                                <div className='default__card'>
                                    <img className="payment__icon" src={visa__icon} alt="" />
                                    <span className='default__card__title'>Default card</span>
                                    <span className='card__number'>Card ending in xxxx</span>

                                    <div className='default__card__bottom'>
                                        <span className='card__holder__name'>Name on Card</span>
                                        <span className='card__expiry__date'>MM/YY</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}
