import React, { useState } from 'react'
import '../../styles/OrderCard.css'
import { MdExpandMore, MdOutlineExpandLess } from "react-icons/md";
import apple from "../../assets/apple_01.jpeg";
import banana from "../../assets/banana_01.png";

export default function OrderCard() {
    const [isExpandItems, setIsExpandItems] = useState(false)

    return (
        <div className='order__card'>
            <div className='d-flex flex-column' style={{ padding: '20px' }}>
                <span className='delivery__method'>Delivery from store</span>
                <span className='delivery__date'>Delivered on 9 May, 2024</span>
            </div>

            <hr className='m-0' />

            <div
                className='d-flex justify-content-between'
                style={{ padding: '20px' }}
                onClick={() => setIsExpandItems(!isExpandItems)}>
                <span>2 items</span>
                {isExpandItems ? <MdOutlineExpandLess /> : <MdExpandMore />}
            </div>

            {isExpandItems
                ?
                <div>
                    <OrderCardRow image01={apple} name={'Apple'} price={2.98} quantity={2} />
                    <OrderCardRow image01={banana} name={'Banana'} price={4.99} quantity={1} />
                </div>
                : <></>}
        </div>
    )
}

const OrderCardRow = ({ image01, name, price, quantity }) => {
    return (
        <div className='order__card__row'>
            <div className='d-flex align-items-center gap-3'>
                <img src={image01} alt='' />

                <div className='d-flex flex-column'>
                    <span>{name}</span>
                    <span className='quantity'>{quantity} ea</span>
                </div>
            </div>

            <span className='total__price'>${price}</span>
        </div>
    )
}