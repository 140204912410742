import React, { useState } from 'react'
import '../../styles/LoginPopup.css'
import { RiCloseFill } from "react-icons/ri";
import { FaFacebookF, FaGoogle } from 'react-icons/fa';

export default function LoginPopup({ setShowLogin }) {

    const [currState, setCurrState] = useState('login')

    return (
        <div className='login__popup'>
            <form>
                <div className='login__popup__title'>
                    {
                        currState === 'login'
                            ? <div>
                                <h4>Welcome!</h4>
                                <h6>Sign up or login to continue</h6>
                            </div>
                            : <h4>Sign Up</h4>
                    }
                    <div className='close' onClick={() => setShowLogin(false)}>
                        <span onClick={() => setShowLogin(false)}><RiCloseFill /></span>
                    </div>
                </div>

                {
                    currState === 'login' ? <SignIn setCurrState={setCurrState} /> : <CreateAccount setCurrState={setCurrState} />
                }
            </form>
        </div>
    )
}

const SignIn = ({ setCurrState }) => {
    return (
        <div className='login__popup__bottom'>
            <div className='d-flex flex-column gap-3'>
                <input type='email' placeholder='Email' required />
                <input type='password' placeholder='Password' required />
            </div>

            <span className='forgotPwd'>Forgot your password?</span>

            <button className='login__popup__button'>Login</button>

            <div className='login__popup__divide'>
                <div className='line'></div>
                <span>or continue with</span>
                <div className='line'></div>
            </div>

            <div className='mb-3 d-flex align-items-center gap-4 justify-content-center'>
                <div className='login__social__icon'>
                    <FaFacebookF />
                </div>
                <div className='login__social__icon'>
                    <FaGoogle />
                </div>
            </div>

            <p>Create a new account? <span onClick={() => setCurrState('signup')}>Click here</span></p>
        </div>
    )
}

const CreateAccount = ({ setCurrState }) => {
    return (
        <div className='login__popup__bottom'>
            <div className='d-flex flex-column gap-3'>
                <input type='text' placeholder='First Name' required />
                <input type='text' placeholder='Last Name' required />
                <input type='email' placeholder='Email' required />
                <input type='text' placeholder='Phone Number' required />
                <input type='password' placeholder='Password' required />
            </div>

            <button className='login__popup__button'>Sign Up</button>

            <div className='login__popup__condition pt-2'>
                <input type='checkbox' required />
                <span>By continuing, I agree to the terms of use & privacy policy.</span>
            </div>

            <p>Already have an account? <span onClick={() => setCurrState('login')}>Login here</span></p>
        </div>
    )
}