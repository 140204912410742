import React from 'react'
import '../styles/OrderDetail.css'
import { Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom';
import AccountMenu from '../components/account/AccountMenu';
import OrderCard from '../components/account/OrderCard';
import visa__icon from '../assets/visa.png'

export default function OrderDetail() {
    return (
        <section className='order__detail'>

            <Container>
                <Link to='/account' className='main__nav'>My account / </Link>
                <Link to='/orders' className='main__nav'>Purchase history / </Link>
                <Link to='/orders/' className='sub__nav'>Order details</Link>
            </Container>

            <Container className='pt-5'>
                <Row>
                    <Col lg='3' md='4'>
                        <AccountMenu currNav={''} />
                    </Col>

                    <Col lg='9' md='8' sm='12' xs='12'>
                        <div className='d-flex flex-column order__details'>
                            <div>
                                <span className='header'>7 May, 2023</span>
                                <span className='order__number'> | Order #8952374-00500</span>
                            </div>

                            <Row className='order__details__container'>
                                <Col lg='8' md='12' sm='12' xs='12'>
                                    <OrderCard />
                                </Col>

                                <Col lg='4' md='12' sm='12' xs='12'>
                                    <div className='delivery__details'>
                                        <span className='delivery__method'>Delivery from store</span>

                                        <div className='d-flex flex-column'>
                                            <span className='title'>Address</span>
                                            <span className='label'>Name 1</span>
                                            <span className='label'>Unit 123, Toronto, ON</span>
                                        </div>

                                        <hr />

                                        <div className='d-flex flex-column'>
                                            <span className='title'>Payment method</span>
                                            <div className='d-flex gap-1 align-items-center'>
                                                <img src={visa__icon} alt='' />
                                                <span className='label'>Ending in xxxx</span>
                                            </div>
                                        </div>

                                        <hr />

                                        <div className='d-flex justify-content-between'>
                                            <span className='title'>Subtotal</span>
                                            <span className='label'>$7.97</span>
                                        </div>

                                        <hr />

                                        <div>
                                            <div className='d-flex justify-content-between'>
                                                <span className='subtitle'>Delivery Fee</span>
                                                <span className='label'>$30</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span className='subtitle'>Driver Tip</span>
                                                <span className='label'>$5.25</span>
                                            </div>
                                        </div>

                                        <hr />

                                        <div className='d-flex justify-content-between'>
                                            <span className='title'>Taxes</span>
                                            <span className='label'>$4.94</span>
                                        </div>

                                        <hr />

                                        <div className='d-flex justify-content-between'>
                                            <span className='title'>Total</span>
                                            <span className='label'>$48.16</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}
