import React from 'react'
import '../styles/Profile.css'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import AccountMenu from '../components/account/AccountMenu'

export default function Profile() {
    return (
        <section className='profile'>
            <Container>
                <Link to='/account' className='main__nav'>My account / </Link><Link to='/account/profile' className='sub__nav'>Personal info</Link>
            </Container>

            <Container className='pt-5'>
                <Row>
                    <Col lg='3' md='4'>
                        <AccountMenu currNav={'Personal info'} />
                    </Col>

                    <Col lg='9' md='8' sm='12' xs='12'>
                        <div className='d-flex flex-column gap-5'>
                            <h4>Personal info</h4>

                            <div className='personal__info'>
                                <span className='header'>Your personal information</span>

                                <div className='d-flex flex-column gap-1 pt-4'>
                                    <span className='title'>Full name</span>
                                    <div className='d-flex justify-content-between'>
                                        <span className='label'>Name 1</span>
                                        <button className='edit__button'>Edit</button>
                                    </div>
                                </div>

                                <hr />
                                <div className='d-flex flex-column gap-1'>
                                    <span className='title'>Email</span>
                                    <div className='d-flex justify-content-between'>
                                        <span className='label'>customer@gmail.com</span>
                                        <button className='edit__button'>Edit</button>
                                    </div>
                                </div>

                                <hr />

                                <div className='d-flex flex-column gap-1'>
                                    <span className='title'>Phone Number</span>
                                    <div className='d-flex justify-content-between'>
                                        <span className='label'>123-456-7890</span>
                                        <button className='edit__button'>Edit</button>
                                    </div>
                                </div>

                                <hr />

                                <div className='d-flex flex-column gap-1'>
                                    <span className='title'>Password</span>
                                    <div className='d-flex justify-content-between'>
                                        <span className='label'>••••••••••••••••••••••••••</span>
                                        <button className='edit__button'>Edit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}
