import React from 'react'
import '../../styles/Footer.css'
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaTiktok } from 'react-icons/fa';
import logo from '../../assets/logo.png'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className='footer'>
      <Container>
        <Row>
          <Col lg='4' md='4' sm='6'>
            <div className='footer__logo text-start'>
              <img src={logo} alt="logo" />
              <h5>ZoodBia</h5>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              </p>
            </div>
          </Col>

          <Col lg='4' md='4' sm='6'>
            <h5 className='footer__title'>COMPANY</h5>
            <ListGroup>
              <ListGroupItem className='list__item border-0 ps-0'>
                <Link to='/home'>Home</Link>
              </ListGroupItem>

              <ListGroupItem className='list__item border-0 ps-0'>
                <Link to='/about-us'>About us</Link>
              </ListGroupItem>

              <ListGroupItem className='list__item border-0 ps-0'>
                <Link to='/delivery'>Delivery</Link>
              </ListGroupItem>


              <ListGroupItem className='list__item border-0 ps-0'>
                <Link to='/privacy-policy'>Privacy Policy</Link>
              </ListGroupItem>
            </ListGroup>
          </Col>

          <Col lg='4' md='4' sm='6'>
            <h5 className='footer__title'>GET IN TOUCH</h5>
            <ListGroup>
              <ListGroupItem className='list__item border-0 ps-0'>
                <p>Location: Toronto, ON</p>
              </ListGroupItem>

              <ListGroupItem className='list__item border-0 ps-0'>
                <span>Phone: 123-456-7890</span>
              </ListGroupItem>

              <ListGroupItem className='list__item border-0 ps-0'>
                <span>Email: zoodbia.com</span>
              </ListGroupItem>
            </ListGroup>
          </Col>

          {/* <Col lg='3' md='4' sm='6'>
            <h5 className='footer__title'>Newsletter</h5>
            <p>Subscribe our newsletter</p>
            <div className='newsletter'>
              <input type='email' placeholder='Enter your email' />
              <span><BsSend className='send__icon' /></span>
            </div>
          </Col> */}
        </Row>

        <Row className='mt-5'>
          <Col lg='6' md='6'>
            <p className='copyright__text'>© Zoodbia. All Rights Reserved</p>
          </Col>

          <Col lg='6' md='6'>
            <div className='social__links  d-flex align-items-center gap-4 justify-content-end'>
              <p className='m-0'>Follow: </p>
              <div className='social__icon'>
                <FaFacebookF />
              </div>
              <div className='social__icon'>
                <BsInstagram />
              </div>
              <div className='social__icon'>
                <BsTwitter />
              </div>
              <div className='social__icon'>
                <FaTiktok />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
