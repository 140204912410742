import React from 'react'
import HomeSlider from '../components/home/HomeSlider'
import Category from '../components/home/Category'
import BestSelling from '../components/home/BestSelling'
import AppDownload from '../components/home/AppDownload'

export default function Home() {

  return (
    <section className='home'>
      <HomeSlider />
      <Category />
      <BestSelling />
      <AppDownload />
    </section>
  )
}
