import React from 'react'
import '../styles/Payment.css'
import { Container, Row, Col } from 'reactstrap'
import paymentOption from '../assets/visa-mastercard.png'
import { useSelector } from 'react-redux'

export default function Payment() {

    const cartTotalAmount = useSelector(state => state.cart.totalAmount)
    const shippingCost = 30

    const totalAmount = cartTotalAmount + Number(shippingCost)

    return (
        <section className='payment'>
            <ProgressBar />

            <section>
                <Container>
                    <Row>
                        <Col lg='8' md='6'>
                            <div className='payment__container'>
                                <div className='credit__card'>
                                    <div className='d-flex justify-content-between'>
                                        <span>Credit card</span>
                                        <img src={paymentOption} alt='' />
                                    </div>
                                    <div className='secure__text'>
                                        <span>All transactions are secure and encrypted.</span>
                                    </div>
                                </div>


                                <form className='credit__card__form'>
                                    <input type='text' placeholder='Card number' required />

                                    <div className='mutli__fields'>
                                        <input type='text' placeholder='Expiry date (MM / YY)' required />
                                        <input type='text' placeholder='Security Code' required />
                                    </div>

                                    <input placeholder='Name on card' required/>

                                </form>

                                <div className='payment__btn mb-4'>
                                    <button className='addToCart__btn'>Pay now</button>
                                </div>
                            </div>
                        </Col>

                        <Col lg='4' md='6'>
                            <div className='checkout__bill'>
                                <h6 className='d-flex align-items-center justify-content-between mb-3'>Subtotal: <span>${cartTotalAmount}</span></h6>
                                <h6 className='d-flex align-items-center justify-content-between mb-3'>Shipping: <span>${shippingCost}</span></h6>
                                <div className='checkout__total'>
                                    <h5 className='d-flex align-items-center justify-content-between'>Total: <span>${totalAmount}</span></h5>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </section>
    )
}

const ProgressBar = () => {
    return (
        <Container>
            <div className='d-flex justify-content-center'>
                <div className='step__row'>
                    <div className='step__col'>
                        <div className='step__circle__completed'>1</div>
                        <span className='step__label__completed'>Cart</span>
                    </div>
                    <div className='step__line__completed'></div>
                    <div className='step__col'>
                        <div className='step__circle__completed'>2</div>
                        <span className='step__label__completed'>Checkout</span>
                    </div>
                    <div className='step__line__completed'></div>
                    <div className='step__col'>
                        <div className='step__circle__active'>3</div>
                        <span className='step__label__active'>Payment</span>
                    </div>
                </div>
            </div>
        </Container>
    )
}