import React from 'react'
import '../../styles/HomeSlider.css'
import img1 from '../../assets/ds-img1.png'
import img2 from '../../assets/ds-img2.png'
import img3 from '../../assets/ds-img3.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from "swiper/modules";
import { Container } from 'reactstrap'
import 'swiper/css';
import 'swiper/css/pagination';

export default function HomeSlider() {
    return (
        <div>
            <Container>
                <Swiper
                    slidesPerView={1}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                >
                    <SwiperSlide className='slider__container'>
                        <div className='slide'>
                            <img className="veg-banner-img" src={img2} alt="" />
                            <img className='veg-background-img' src={img1} alt="" />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className='slider__container'>
                        <div className='slide'>
                            <img className='discount-banner-img' src={img3} alt="" />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Container>
        </div>
    )
}
