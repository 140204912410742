import React from 'react'
import '../../styles/ProductCard.css'
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../../store/shoppingCart/cartSlice';
import { Link } from 'react-router-dom';

export default function ProductCard(props) {
  const { id, name, image01, price } = props.item;
  const cartItems = useSelector(state => state.cart.cartItems)
  const existingItem = cartItems.find(item => item.id === id)
  const dispatch = useDispatch();

  const addToCart = () => {
    dispatch(cartActions.addItem({
      id,
      name,
      image01,
      price
    }))

    console.log(cartItems)
  }

  const removeFromCart = () => {
    dispatch(cartActions.removeItem(id))
  }

  return (
    <div className='product__item'>
      <div className='info'>
        <Link to={`/products/${id}`}>
          <div style={{ textAlign: 'center' }}>
            <img src={image01} alt="" />
          </div>

          <div className='d-flex flex-column gap-2'>
            <span className='name'>{name}</span>
            <span className='price'>${price}</span>
          </div>
        </Link>
        
        <div className='counter'>
          {
            !existingItem
              ? <span onClick={addToCart} className='add'><FaCirclePlus className='addToCart__icon' /></span>
              : <div className='quantity__counter'>
                <span onClick={removeFromCart}><FaCircleMinus className='removeFromCart__icon' /></span>
                <span>{existingItem.quantity}</span>
                <span onClick={addToCart}><FaCirclePlus className='addToCart__icon' /></span>
              </div>
          }
        </div>
      </div>
    </div>
  )
}
