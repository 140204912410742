import React from 'react'
import '../../styles/AccountMenu.css'
import { GrNotes } from "react-icons/gr";
import { BiWallet } from "react-icons/bi";
import { GoPerson, GoSignOut } from "react-icons/go";
import { CiLocationOn } from "react-icons/ci";
import { MdLockOutline, MdOutlineHelpOutline, MdInfoOutline } from "react-icons/md";
import { Link } from 'react-router-dom';

export default function AccountMenu({ currNav }) {
    return (
        <div className='account__menu'>
            <h4>My account</h4>

            <ul>
                <li className={currNav === 'Purchase history' ? 'selected__bg' : ''}>
                    <Link to='/orders'>
                        <div className='menu__option'>
                            <GrNotes />
                            <span>Purchase history </span>
                        </div>
                    </Link>
                </li>
                <li className={currNav === 'Wallet' ? 'selected__bg' : ''}>
                    <Link to='/wallet'>
                        <div className='menu__option'>
                            <BiWallet />
                            <span>Wallet</span>
                        </div>
                    </Link>
                </li>
            </ul>
            <hr />
            <h6>Manage Account</h6>
            <ul>
                <li className={currNav === 'Personal info' ? 'selected__bg' : ''}>
                    <Link to='/account/profile'>
                        <div className='menu__option'>
                            <GoPerson />
                            <span>Personal info</span>
                        </div>
                    </Link>
                </li>
                <li className={currNav === 'Addresses' ? 'selected__bg' : ''}>
                    <Link to='/account/addresses'>
                        <div className='menu__option'>
                            <CiLocationOn />
                            <span>Addresses</span>
                        </div>
                    </Link>
                </li>
            </ul>
            <hr />
            <h6>Privacy</h6>
            <ul>
                <li className={currNav === 'Privacy policy' ? 'selected__bg' : ''}>
                    <div className='menu__option'>
                        <MdLockOutline />
                        <span>Privacy policy</span>
                    </div>
                </li>
            </ul>
            <hr />
            <h6>Customer Service</h6>
            <ul>
                <li className={currNav === 'Help' ? 'selected__bg' : ''}>
                    <div className='menu__option'>
                        <MdOutlineHelpOutline />
                        <span>Help</span>
                    </div>
                </li>
                <li className={currNav === 'Terms of Use' ? 'selected__bg' : ''}>
                    <div className='menu__option'>
                        <MdInfoOutline />
                        <span>Terms of Use</span>
                    </div>
                </li>
            </ul>
            <hr />
            <ul>
                <li>
                    <div className='menu__option'>
                        <GoSignOut />
                        <span>Sign out</span>
                    </div>
                </li>
            </ul>
        </div>
    )
}
