import React from 'react'
import '../styles/Cart.css'
import { useSelector, useDispatch } from 'react-redux'
import { Container } from 'reactstrap'
import { cartActions } from '../store/shoppingCart/cartSlice'
import { Link } from 'react-router-dom'
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";

export default function Cart() {

  const cartItems = useSelector(state => state.cart.cartItems)
  const totalAmount = useSelector(state => state.cart.totalAmount)

  return (
    <section className='cart'>
      <ProgressBar />

      <section>
        <Container>
          {
            cartItems.length === 0
              ? <h5 className='text-center'>Your cart is empty</h5>
              : <div>
                <div className='cart__items__title'>
                  <span>Title</span>
                  <span className='quantity'>Quantity</span>
                  <span>Total</span>
                </div>
                <hr />
                {
                  cartItems.map(item => <CartRow item={item} key={item.id} />)
                }
              </div>
          }

          <div className='mt-4'>
            <h6 className='subtotal'>Subtotal: ${" "} <span>{totalAmount}</span></h6>
            <p className='checkout__info'>Taxes and shipping will calculate at checkout</p>
            <div className='checkout__btn'>
              <button className='addToCart__btn me-4'><Link to='/products'>Continue shopping</Link></button>
              <button className='addToCart__btn'><Link to='/checkout'>Proceed to checkout</Link></button>
            </div>
          </div>
        </Container>
      </section>
    </section>
  )
}

const ProgressBar = () => {
  return (
    <Container>
      <div className='d-flex justify-content-center'>
        <div className='step__row'>
          <div className='step__col'>
            <div className='step__circle__active'>1</div>
            <span className='step__label__active'>Cart</span>
          </div>
          <div className='step__line__pending'></div>
          <div className='step__col'>
            <div className='step__circle__pending'>2</div>
            <span className='step__label__pending'>Checkout</span>
          </div>
          <div className='step__line__pending'></div>
          <div className='step__col'>
            <div className='step__circle__pending'>3</div>
            <span className='step__label__pending'>Payment</span>
          </div>
        </div>
      </div>
    </Container>
  )
}

const CartRow = (props) => {
  const { id, image01, name, price, quantity } = props.item
  const dispatch = useDispatch()

  const deleteItem = () => {
    dispatch(cartActions.deleteItem(id))
  }

  const addToCart = () => {
    dispatch(cartActions.addItem({
      id,
      name,
      image01,
      price
    }))
  }

  const removeFromCart = () => {
    dispatch(cartActions.removeItem(id))
  }

  return (
    <div>
      <div className='cart__items__title cart__items__item'>
        <div className='d-flex gap-3'>
          <img src={image01} alt="" />
          <div className='cart__product__details'>
            <span className='name'>{name}</span>
            <span className='price'>${price}</span>

            <div className='left__counter'>
              <span onClick={removeFromCart}><FaCircleMinus className='removeFromCart__icon' /></span>
              <span>{quantity}</span>
              <span onClick={addToCart}><FaCirclePlus className='addToCart__icon' /></span>
            </div>

            <button onClick={deleteItem} className='removeFromCart__btn'>Remove</button>
          </div>
        </div>

        <div className='middle__counter'>
          <span onClick={removeFromCart}><FaCircleMinus className='removeFromCart__icon' /></span>
          <span>{quantity}</span>
          <span onClick={addToCart}><FaCirclePlus className='addToCart__icon' /></span>
        </div>

        <span>${Number(price) * Number(quantity)}</span>
      </div>
      <hr />
    </div>
  )
}