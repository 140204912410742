import React from 'react'
import '../../styles/CartPane.css'
import CartItem from './CartItem'
import { ListGroup } from 'reactstrap'
import { RiCloseFill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cartUiActions } from "../../store/shoppingCart/cartUiSlice";

export default function CartPane() {

    const dispatch = useDispatch()
    const cartProducts = useSelector((state) => state.cart.cartItems)
    const totalAmount = useSelector((state) => state.cart.totalAmount)

    const toggleCart = () => {
        dispatch(cartUiActions.toggle());
    }

    return (
        <div className='cart__container'>
            <ListGroup className='cart__content'>
                <div className='cart__close' onClick={toggleCart}>
                    <span><RiCloseFill /></span>
                </div>

                <div className='cart__item-list pb-3'>
                    {cartProducts.length === 0 ? (
                        <h6 className="text-center mt-5">No item added to the cart</h6>
                    ) : (
                        cartProducts.map((item, index) => (
                            <CartItem item={item} key={index} />
                        ))
                    )}
                </div>

                <div className='cart__bottom d-flex align-items-center justify-content-between'>
                    <h6>Subtotal: <span>${totalAmount}</span></h6>
                    <button><Link to='/checkout'>Checkout</Link></button>
                </div>
            </ListGroup>
        </div>
    )
}
