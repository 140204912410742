import React from 'react'
import '../styles/Account.css'
import { Col, Container, Row } from 'reactstrap'
import { GrNotes } from "react-icons/gr";
import { BiWallet } from "react-icons/bi";
import { GoPerson } from "react-icons/go";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import visa__icon from '../assets/visa.png'
import { Link } from 'react-router-dom';
import AccountMenu from '../components/account/AccountMenu';

export default function Account() {

    return (
        <section className='account'>

            <Container>
                <Link to='/account' className='main__nav'>My account / </Link><Link to='/account' className='sub__nav'>Home</Link>
            </Container>

            <Container className='pt-5'>
                <Row>
                    <Col lg='3' md='4'>
                        <AccountMenu currNav={''} />
                    </Col>

                    <Col lg='9' md='8' sm='12' xs='12'>
                        <div className='d-flex flex-column gap-5'>
                            <h4>Welcome to your account</h4>

                            <div className='account__container'>
                                <Link to='/orders'>
                                    <div className='account__purchase__history'>
                                        <div className='d-flex gap-3 align-items-center'>
                                            <GrNotes />
                                            <span className='account__header'>Purchase history </span>
                                        </div>
                                        <MdOutlineKeyboardArrowRight />
                                    </div>
                                </Link>
                            </div>

                            <div className='account__container'>
                                <Link to='/wallet'>
                                    <div className='account__wallet'>
                                        <div className='d-flex gap-3 align-items-center'>
                                            <BiWallet />
                                            <span className='account__header'>Wallet <span className='account__subheader'>| Payment methods (1)</span></span>
                                        </div>
                                        <MdOutlineKeyboardArrowRight />
                                    </div>
                                </Link>

                                <div className='wallet__content'>
                                    <div className='default__card'>
                                        <img className="payment__icon" src={visa__icon} alt="" />
                                        <span className='default__card__title'>Default card</span>
                                        <span className='card__number'>Card ending in xxxx</span>

                                        <div className='d-flex justify-content-between pt-4'>
                                            <span className='card__holder__name'>Name on Card</span>
                                            <span className='card__expiry__date'>MM/YY</span>
                                        </div>
                                    </div>

                                    <div className='add__payment__method'>
                                        <button>Add new payment method</button>
                                    </div>
                                </div>
                            </div>

                            <div className='account__container'>
                                <Link to='/account/profile'>
                                    <div className='account__info'>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex gap-3'>
                                                <GoPerson />
                                                <div className='d-flex flex-column' style={{ marginTop: '-3px' }}>
                                                    <span className='account__header'>Account Info</span>
                                                    <span className='account__subheader'>Addresses, contact information and password</span>
                                                </div>
                                            </div>
                                        </div>
                                        <MdOutlineKeyboardArrowRight />
                                    </div>
                                </Link>

                                <div className='account__info__content'>
                                    <div className='email__and__phone'>
                                        <div className='email'>
                                            <div className='d-flex flex-column p-4'>
                                                <span className='account__info__detail__header'>Email Address</span>
                                                <span className='account__info__detail__label'>customer@gmail.com</span>
                                            </div>
                                        </div>
                                        <div className='phone'>
                                            <div className='d-flex flex-column p-4'>
                                                <span className='account__info__detail__header'>Phone Number</span>
                                                <span className='account__info__detail__label'>123-456-7890</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='addresses'>
                                        <div className='d-flex flex-column p-4'>
                                            <span className='account__info__detail__header'>Addresses</span>
                                            <span className='account__info__detail__label'>Unit 123, Toronto, ON</span>
                                        </div>

                                        <Link to='/account/addresses'>
                                            <button className='account__view__addresses-btn'>View addresses</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}