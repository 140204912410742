import React from 'react'
import '../styles/PurchaseHistory.css'
import { Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom';
import AccountMenu from '../components/account/AccountMenu';
import apple from "../assets/apple_01.jpeg";
import banana from "../assets/banana_01.png";

const orders = [
    {
        id: "8952374-00500",
        orderDate: "7 May, 2024",
        deliveryDate: "9 May, 2024",
        totalAmount: 80.26,
        products: [
            {
                id: "10",
                name: 'Apple',
                image01: apple,
                price: 1.49,
                quantity: 2,
                totalPrice: 2.98
            },
            {
                id: "11",
                name: 'Banana',
                image01: banana,
                price: 4.99,
                quantity: 1,
                totalPrice: 4.99
            }
        ]
    },
]

export default function PurchaseHistory() {
    return (
        <section className='purchase__history'>
            <Container>
                <Link to='/account' className='main__nav'>My account / </Link><Link to='/orders' className='sub__nav'>Purchase history</Link>
            </Container>

            <Container className='pt-5'>
                <Row>
                    <Col lg='3' md='4'>
                        <AccountMenu currNav={'Purchase history'} />
                    </Col>

                    <Col lg='9' md='8' sm='12' xs='12'>
                        <div className='d-flex flex-column gap-5'>
                            <h4>Purchase history</h4>

                            {
                                orders.map((item, index) => (
                                    <Link to={'/orders/' + item.id}>
                                        <div className='order__history__content'>
                                            <div className='order__history__header'>
                                                <div className='order__history__summary'>
                                                    <span className='date'>{item.orderDate}</span>
                                                    <span className='order__number'>| Order #{item.id} | Total ${item.totalAmount}</span>
                                                </div>
                                                <button>View details</button>
                                            </div>
                                            <div className='order__history__details'>
                                                <span className='delivery__method'>Delivery from store</span>
                                                <span className='delivery__date'>Delivered on {item.deliveryDate}</span>

                                                <span className='number__of__items'>{item.products.length} items</span>
                                                <ul className='order__history__list'> {/*display max 6 item */}
                                                    {
                                                        item.products.map((item, index) => (
                                                            <li className='order__history__list__item'>
                                                                <OrderItems item={item} />
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

const OrderItems = (props) => {

    const { image01, quantity } = props.item;

    return (
        <div className='order__history__item'>
            <img src={image01} alt='' />
            {quantity > 1 ?
                <span className='order__badge'>{quantity}</span>
                : <></>
            }
        </div>
    )
}