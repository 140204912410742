import React from 'react'
import '../../styles/CartItem.css'
import { ListGroupItem } from 'reactstrap'
import { RiAddLine, RiSubtractLine, RiCloseLine } from "react-icons/ri";
import { useDispatch } from 'react-redux';
import { cartActions } from '../../store/shoppingCart/cartSlice';

export default function CartItem({ item }) {

    const { id, name, price, image01, quantity, totalPrice } = item;

    const dispatch = useDispatch()
    const addItem = () => {
        dispatch(cartActions.addItem({
            id,
            name,
            price,
            image01
        }))
    }

    const removeItem = () => {
        dispatch(cartActions.removeItem(id))
    }

    const deleteItem = () => {
        dispatch(cartActions.deleteItem(id))
    }

    return (
        <ListGroupItem className='cart__item border-0'>
            <div className='cart__item-info d-flex gap-2'>
                <img src={image01} alt="product-img" />

                <div className='cart__product-info w-100 d-flex align-items-center gap-4 justify-content-between'>
                    <div>
                        <h6 className='cart__product-title'>{name}</h6>
                        <p className='cart__product-price d-flex align-items-center gap-5'>
                            {quantity}x <span>${totalPrice}</span>
                        </p>
                        <div className='quantity__btn d-flex align-items-center justify-content-between'>
                            <span className='add__btn' onClick={addItem}><RiAddLine /></span>
                            <span className='quantity'>{quantity}</span>
                            <span className='subtract__btn' onClick={removeItem}><RiSubtractLine /></span>
                        </div>
                    </div>

                    <span className='delete__btn' onClick={deleteItem}><RiCloseLine /></span>
                </div>
            </div>
        </ListGroupItem>
    )
}
