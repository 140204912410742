import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import Home from '../pages/Home'
import ProductList from '../pages/ProductList'
import ProductDetail from '../pages/ProductDetail'
import Cart from '../pages/Cart'
import Checkout from '../pages/Checkout'
import Contact from '../pages/Contact'
import Payment from '../pages/Payment'
import Account from '../pages/Account'
import PurchaseHistory from '../pages/PurchaseHistory'
import Wallet from '../pages/Wallet'
import Profile from '../pages/Profile'
import DeliveryAddresses from '../pages/DeliveryAddresses'
import OrderDetail from '../pages/OrderDetail'

export default function Routers() {
  return (
    <Routes>
        <Route path='/' element={<Navigate to='/home/'/>} />
        <Route path='/home' element={<Home />} />
        <Route path='/products' element={<ProductList />} />
        <Route path='/products/:id' element={<ProductDetail />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/payment' element={<Payment />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/account' element={<Account />} />
        <Route path='/orders' element={<PurchaseHistory />} />
        <Route path='/orders/:id' element={<OrderDetail />} />
        <Route path='/wallet' element={<Wallet />} />
        <Route path='/account/profile' element={<Profile />} />
        <Route path='/account/addresses' element={<DeliveryAddresses />} />
    </Routes>
  )
}
