import React, { useState } from 'react'
import '../styles/ProductList.css'
import '../styles/Pagination.css'
import { Container, Row, Col } from 'reactstrap'
import { RiSearchLine } from "react-icons/ri";
import { MdExpandMore } from "react-icons/md";
import products from '../data/products'
import ProductCard from '../components/product/ProductCard'
import ReactPaginate from 'react-paginate';

export default function ProductList() {

  const [searchTerm, setSearchTerm] = useState("")
  const [pageNumber, setPageNumber] = useState(0)

  const searchedProduct = products.filter(item => {
    if (searchTerm.value === "") {
      return item
    }
    if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      return item
    }

    return console.log("not found")
  })

  const productPerPage = 10
  const visitedPage = pageNumber * productPerPage
  const displayPage = searchedProduct.slice(visitedPage, visitedPage + productPerPage)

  const pageCount = Math.ceil(searchedProduct.length / productPerPage)

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  return (
    <section className='product__list'>
      <Container>
        <h4>Products</h4>
      </Container>

      <section>
        <Container>
          <Row>
            <Col lg='6' md='6' sm='6' xs='12'>
              <div className='search__widget'>
                <input
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  type='text'
                  placeholder='Search for product' />
                <RiSearchLine />
              </div>
            </Col>

            <Col lg='6' md='6' sm='6' xs='12' className='mb-5'>
              <div className="sorting__widget">
                <select>
                  <option>Default</option>
                  <option value='ascending'>Alphabetically, A-Z</option>
                  <option value='descending'>Alphabetically, Z-A</option>
                  <option value='high-price'>High Price</option>
                  <option value='low-price'>Low Price</option>
                </select>
                
                <MdExpandMore />
              </div>
            </Col>
          </Row>

          <Row className='g-4 row-cols-lg-5 row-cols-xs-2 row-cols-md-3'>
            {
              displayPage.map(item =>
                <Col key={item.id} className='mb-4'>
                  <ProductCard item={item} />
                </Col>
              )
            }
          </Row>

          <Row>
            <div>
              <ReactPaginate
                pageCount={pageCount}
                onPageChange={changePage}
                previousLabel={'Prev'}
                nextLabel={'Next'}
                containerClassName=' paginationBtns '
              />
            </div>
          </Row>
        </Container>
      </section>
    </section>
  )
}
