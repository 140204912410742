import React from 'react'
import '../../styles/AddressCard.css'

export default function AddressCard(props) {
    const { name, address, isDefault } = props.item;

    return (
        <div className='address__card'>
            <div className='left__container'>
                <span className='name'>{name}</span>
                <span className='address'>{address}</span>
                {isDefault ? <span className='preferred__label'>Preferred delivery address</span> : <></>}
            </div>

            <div className='right__container'>
                <button>Edit</button>
                <span> | </span>
                <button>Remove</button>
            </div>
        </div>
    )
}