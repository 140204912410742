import React, { useState, useEffect } from 'react'
import '../styles/ProductDetail.css'
import products from '../data/products'
import { useParams } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap'
import ProductCard from '../components/product/ProductCard'
import { useDispatch } from 'react-redux';
import { cartActions } from '../store/shoppingCart/cartSlice';

export default function ProductDetail() {

  const [tab, setTab] = useState('desc')
  const [enteredName, setEnteredName] = useState('')
  const [enteredEmail, setEnteredEmail] = useState('')
  const [reviewMsg, setReviewMsg] = useState('')
  const { id } = useParams();
  const dispatch = useDispatch();

  const product = products.find((product) => product.id === id)
  const [previewImg, setPreviewImg] = useState(product.image01)

  const { name, price, category, desc, image01 } = product

  const relatedProduct = products.filter(item => category === item.category)

  const addItem = () => {
    dispatch(
      cartActions.addItem({
        id,
        name,
        price,
        image01,
      })
    );
  };

  const submitHandler = e => {
    e.preventDefault()
    console.log(enteredName, enteredEmail, reviewMsg)
  }

  useEffect(() => {
    setPreviewImg(product.image01)
  }, [product])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [product])

  return (
    <section className='product__detail'>
      <Container>
        <Row>
          <Col lg='2' md='2'>
            <div className='images'>
              <div
                className='mb-3 image__container'
                style={{cursor: "pointer"}}
                onClick={() => setPreviewImg(product.image01)}>
                <img src={product.image01} alt="" />
              </div>

              <div
                className='image__container'
                onClick={() => setPreviewImg(product.image02)}>
                <img src={product.image02} alt="" />
              </div>
            </div>
          </Col>

          <Col lg='4' md='4'>
            <div className='preview__img'>
              <img src={previewImg} alt="" className='w-100' />
            </div>
          </Col>

          <Col lg='6' md='6'>
            <div className='product__content'>
              <h2 className='title mb-3'>{name}</h2>
              <p className='price'>{" "} Price: <span>${price}</span></p>
              <p className='category mb-5'>Category: <span>{category}</span></p>

              <button className='addToCart__btn' onClick={addItem}>Add to Cart</button>
            </div>
          </Col>

          <Col lg='12'>
            <div className='tabs d-flex align-items-center gap-3'>
              <h6
                className={`${tab === 'desc' ? 'tab__active' : ''}`}
                onClick={() => setTab('desc')}>
                Description
              </h6>
              <h6
                className={`${tab === 'rev' ? 'tab__active' : ''}`}
                onClick={() => setTab('rev')}>
                Review
              </h6>
            </div>

            {
              tab === 'desc' ?
                <div className='description'>
                  <p>{desc} </p>
                </div> :
                <div className='review__form mb-3'>
                  <div className='review pt-4'>
                    <p className='user__name mb-0'>John</p>
                    <p className='user__email'>john@gmail.com</p>
                    <p className='feedback__text'>Great Product</p>
                  </div>


                  <div className='review'>
                    <p className='user__name mb-0'>John</p>
                    <p className='user__email'>john@gmail.com</p>
                    <p className='feedback__text'>Great Product</p>
                  </div>

                  <div className='review'>
                    <p className='user__name mb-0'>John</p>
                    <p className='user__email'>john@gmail.com</p>
                    <p className='feedback__text'>Great Product</p>
                  </div>

                  <form onSubmit={submitHandler}>
                    <div className='form__group'>
                      <input
                        type='text'
                        placeholder='Enter your name'
                        onChange={e => setEnteredName(e.target.value)}
                        required />
                    </div>

                    <div className='form__group'>
                      <input
                        type='text'
                        placeholder='Enter your email'
                        onChange={e => setEnteredEmail(e.target.value)}
                        required />
                    </div>

                    <div className='form__group'>
                      <textarea
                        rows={8}
                        type='text'
                        placeholder='Write your review'
                        onChange={e => setReviewMsg(e.target.value)}
                        required />
                    </div>

                    <button type='submit' className='addToCart__btn'>Submit</button>
                  </form>
                </div>
            }
          </Col>

          <Col lg='12' className='mb-5 mt-4'>
            <h2 className='related__product-title'>You might also like</h2>
          </Col>

          <Row className='g-4 row-cols-lg-5 row-cols-xs-2 row-cols-md-3'>
            {
              relatedProduct.map(item => (
                <Col className='mb-4' key={item.id}>
                  <ProductCard item={item} />
                </Col>
              ))
            }
          </Row>
        </Row>
      </Container>
    </section>
  )
}