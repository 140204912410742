import React, { useState } from 'react'
import '../styles/Checkout.css'
import { useSelector } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

export default function Checkout() {

  const [enterFirstName, setEnterFirstName] = useState('')
  const [enterLastName, setEnterLastName] = useState('')
  const [enterPhoneNumber, setEnterPhoneNumber] = useState('')
  const [enterAddress, setEnterAddress] = useState('')
  const [enterApartment, setEnterApartment] = useState('')
  const [enterCountry, setEnterCountry] = useState('')
  const [enterCity, setEnterCity] = useState('')
  const [enterProvince, setEnterProvince] = useState('')
  const [enterPostalCode, setEnterPostalCode] = useState('')

  const shippingInfo = []

  const cartTotalAmount = useSelector(state => state.cart.totalAmount)
  const shippingCost = 30

  const totalAmount = cartTotalAmount + Number(shippingCost)

  const submitHandler = e => {
    e.preventDefault()
    const userShippingAddress = {
      firstName: enterFirstName,
      lastName: enterLastName,
      phoneNum: enterPhoneNumber,
      address: enterAddress,
      apartment: enterApartment,
      country: enterCountry,
      city: enterCity,
      province: enterProvince,
      postalCode: enterPostalCode
    }

    shippingInfo.push(userShippingAddress)
    console.log(userShippingAddress)
  }

  return (
    <section>
      <ProgressBar />

      <section>
        <Container>
          <Row>
            <Col lg='8' md='6'>
              <h6 className='mb-4'>Shipping Address</h6>
              <form className='checkout__form' onSubmit={submitHandler}>
                <div className='mutli__fields'>
                  <input type='text' placeholder='First Name' onChange={e => setEnterFirstName(e.target.value)} required />
                  <input type='text' placeholder='Last Name' onChange={e => setEnterLastName(e.target.value)} required />
                </div>
                <input type='number' placeholder='Phone number' onChange={e => setEnterPhoneNumber(e.target.value)} required />
                <input type='text' placeholder='Adress' onChange={e => setEnterAddress(e.target.value)} required />
                <input type='text' placeholder='Apartment, suite, etc. (optional)' onChange={e => setEnterApartment(e.target.value)} required />
                <input type='text' placeholder='Country' onChange={e => setEnterCountry(e.target.value)} required />
                <div className='mutli__fields'>
                  <input type='text' placeholder='City' onChange={e => setEnterCity(e.target.value)} required />
                  <input type='text' placeholder='Province' onChange={e => setEnterProvince(e.target.value)} required />
                </div>
                <input type='text' placeholder='Postal code' onChange={e => setEnterPostalCode(e.target.value)} required />
                <button type='submit' className='addToCart__btn mt-3'>
                  <Link to='/payment'>Payment</Link>
                </button>
              </form>
            </Col>

            <Col lg='4' md='6'>
              <div className='checkout__bill'>
                <h6 className='d-flex align-items-center justify-content-between mb-3'>Subtotal: <span>${cartTotalAmount}</span></h6>
                <h6 className='d-flex align-items-center justify-content-between mb-3'>Shipping: <span>${shippingCost}</span></h6>
                <div className='checkout__total'>
                  <h5 className='d-flex align-items-center justify-content-between'>Total: <span>${totalAmount}</span></h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  )
}

const ProgressBar = () => {
  return (
    <Container>
      <div className='d-flex justify-content-center'>
        <div className='step__row'>
          <div className='step__col'>
            <div className='step__circle__completed'>1</div>
            <span className='step__label__completed'>Cart</span>
          </div>
          <div className='step__line__completed'></div>
          <div className='step__col'>
            <div className='step__circle__active'>2</div>
            <span className='step__label__active'>Checkout</span>
          </div>
          <div className='step__line__pending'></div>
          <div className='step__col'>
            <div className='step__circle__pending'>3</div>
            <span className='step__label__pending'>Payment</span>
          </div>
        </div>
      </div>
    </Container>
  )
}