import React from 'react'
import { Container } from 'reactstrap'

export default function Contact() {
  return (
    <section>
      <Container>
        <h4>Contact</h4>
      </Container>
    </section>
  )
}
