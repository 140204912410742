import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import ProductCard from '../product/ProductCard'
import redPepper from '../../assets/red-pepper_01.png'
import banana from '../../assets/banana_01.png'
import blueberry from '../../assets/blueberry_01.jpeg'
import mango from '../../assets/mango_01.jpeg'
import egg from '../../assets/egg_01.png'
import mayonnaise from '../../assets/mayonnaise_01.png'

const productData = [
    {
        id: "01",
        name: 'Red Pepper',
        image01: redPepper,
        price: 6.49
    },
    {
        id: "11",
        name: 'Banana',
        image01: banana,
        price: 4.99
    },
    {
        id: "12",
        name: 'Blueberry',
        image01: blueberry,
        price: 2.49
    },
    {
        id: "05",
        name: 'Mango',
        image01: mango,
        price: 0.49
    },
    {
        id: "04",
        name: 'Egg',
        image01: egg,
        price: 1.99
    },
    {
        id: "06",
        name: 'Mayonnaise',
        image01: mayonnaise,
        price: 4.99
    }
]

export default function BestSelling() {
    return (
        <div>
            <Container>
                <div className='mt-5'>
                    <h2>Best Selling</h2>
                </div>
                
                <Row className='g-4 row-cols-lg-5 row-cols-xs-2 row-cols-md-3'>
                    {productData.map((item, index) => (
                        <Col className='mt-5'>
                            <ProductCard item={item} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>

    )
}
