import './App.css';
import React, { useEffect, useState } from 'react'
import Header from '../src/components/common/Header'
import Footer from '../src/components/common/Footer'
import Routes from '../src/routes/Routers'
import CartPane from '../src/components/cart/CartPane'
import { useSelector } from "react-redux";
import LoginPopup from './components/common/LoginPopup';
import { useLocation } from 'react-router-dom';

function App() {

  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "auto"})
  }, [pathname]);

  const [showLogin, setShowLogin] = useState(false)
  const showCart = useSelector(state => state.cartUiSlice.cartIsVisible)

  return (
    <>
      {showLogin ? <LoginPopup setShowLogin={setShowLogin} /> : <></>}
      <div className='app'>
        <Header setShowLogin={setShowLogin}/>
        {showCart && <CartPane />}
        <div>
          <Routes />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default App;
